import axios from 'axios'
import { useRecoilState } from 'recoil';
import { authAtom } from '../state/authState'
import { router } from './router';

export const useRequestHandler = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const navigate = router.navigate

  const requestHandler = axios.create({
    baseURL: "https://cloud.antalyse.com",
    headers: {
      "Content-type": "application/json",
    }
  })
  
  const errorComposer = (error: any) => {
    return () => {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted')
      }
  
      if (statusCode === 401) {
        localStorage.removeItem('user');
        setAuth(null);
        navigate('/');

        console.log('Please login to access this resource')
      }
    }
  }
  
  requestHandler.interceptors.request.use(
    config => {
      config.headers['api-key'] = `${auth}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  
  requestHandler.interceptors.response.use(undefined, function (error) {
    error.handleGlobally = errorComposer(error);
  
    return Promise.reject(error);
  })

  return { requestHandler }
}
