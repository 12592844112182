import { useSetRecoilState } from 'recoil'
import { useRequestHandler } from '../util/useRequestHandler'
import { authAtom } from '../state/authState';
import { userAtom } from '../state/userState';
import { router } from '../util/router';

export const useUserActions = () => {
  const { requestHandler } = useRequestHandler()
  const navigate = router.navigate
  
  const setAuth = useSetRecoilState(authAtom);
  const setUser = useSetRecoilState(userAtom);

  return {
    login,
    logout,
    register,
  }

  async function login(email: string, password: string) {
    try {
      const response = await requestHandler.post('/authentication/login', { email, password })
      localStorage.setItem('user', JSON.stringify(response.data));
      setUser(JSON.stringify(response.data));
      setAuth(response.data.apiKey)
      localStorage.setItem('auth', response.data.apiKey);
      navigate('/dashboard')
    } catch(error) {
      return Promise.reject(error)
    }
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    setAuth(null);
    navigate('/login');
  }

  async function register(email: string, password: string, name: string) {
    try {
      const response = await requestHandler.post('/authentication/register', { email, password, name })
      if (response.data.apiKey) {
        await requestHandler.post(`/contentx/firstLogin?apikey=${response.data.apiKey}`)
      }
      navigate('/login')
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
